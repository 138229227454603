import ITrainingLocationDateResponse from '@/interfaces/training/TrainingLocationDateInterface'
import TrainingLocation from '@/classes/training/training-location'
import TrainingData from '@/classes/training/training'
import { ITrainingFormLocationDate } from '@/interfaces/form-interfaces/TrainingDataFormInterface'
import Model from '@/classes/model'
import TrainingLocationDateService from '@/classes/training/training-location-date-service'
import StudentUserStatus, { StudentUserStatusTitle } from '@/configs/student-user-status'

export default class TrainingLocationDate extends Model {
  from!: string
  to!: string
  status!: string
  attendedStatus!: string
  durationInDays!: number
  seats!: number
  seatsTaken!: number
  userCount!: number
  price!: string
  priceWithVat!: string
  total!: string
  vat!: number
  trainingId!: number
  trainingLocationId!: number
  isRefresh!: boolean
  localId!: number
  slug!: string
  services!: TrainingLocationDateService[]
  takenSeats!: boolean
  training!: TrainingData
  trainingLocation!: TrainingLocation
  trainingLocationDateServices?: TrainingLocationDateService[]
  language!: string
  title!: string | null

  constructor(data: ITrainingLocationDateResponse) {
    super(data)

    this.from = data.from
    this.to = data.to
    this.status = data.status
    this.durationInDays = data.duration_in_days
    this.seats = data.seats
    this.userCount = data.user_count
    this.price = data.price
    this.priceWithVat = data.price_with_vat
    this.total = data.total
    this.seatsTaken = data.seats_taken
    this.vat = data.vat
    this.trainingId = data.training_id
    this.trainingLocationId = data.training_location_id
    this.isRefresh = data.is_refresh
    this.localId = data.local_id
    this.slug = data.slug
    this.attendedStatus = data.attended_status
    this.language = data.language

    this.services = this.setRelation(data.services, TrainingLocationDateService, [])
    this.takenSeats = !!data.taken_seats
    this.training = this.setRelation(data.training, TrainingData)
    this.trainingLocation = this.setRelation(data.training_location, TrainingLocation)
    this.trainingLocationDateServices = this.setRelation(
      data.training_location_date_services,
      TrainingLocationDateService
    )
    this.title = data.title || null
  }

  toFormData(): ITrainingFormLocationDate {
    return {
      uuid: this.uuid,
      from: this.from,
      to: this.to,
      seats: this.seats,
      price: Number(this.price),
      vat: this.vat,
      trainingLocationUuid: this.trainingLocation.uuid,
      trainingUuid: this.training.uuid,
      isRefresh: this.isRefresh,
    }
  }

  get activeServices(): TrainingLocationDateService[] {
    return this.services.filter((service: TrainingLocationDateService) => service.isActive)
  }

  get readableUserStatus(): string {
    if (this.attendedStatus === StudentUserStatus.attended) return StudentUserStatusTitle[StudentUserStatus.attended]

    if (this.attendedStatus === StudentUserStatus.notAttended)
      return StudentUserStatusTitle[StudentUserStatus.notAttended]

    if (this.attendedStatus === StudentUserStatus.refunded) return StudentUserStatusTitle[StudentUserStatus.refunded]

    return ''
  }
}
