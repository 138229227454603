import { AxiosResponse } from 'axios'
import _ from 'lodash'
import http from '@/classes/http'
import Classifier from '@/classes/training/classifier'
import TrainingLocation from '@/classes/training/training-location'
import TrainingData from '@/classes/training/training'
import TrainingLocationDate from '@/classes/training/training-location-date'
import TrainingLocationDateService from '@/classes/training/training-location-service'
import IClassifierResponse, { IClassifiersById } from '@/interfaces/training/ClassifierInterface'
import ITrainingLocationDateResponse from '@/interfaces/training/TrainingLocationDateInterface'
import ITrainingLocationDateServiceResponse from '@/interfaces/training/TrainingLocationDateServiceInterface'
import ITrainingLocationResponse from '@/interfaces/training/TrainingLocationInterface'
import Filter from '@/classes/filter'
import ITrainingDataForm, {
  ITrainingFormLocation,
  ITrainingFormLocationDate,
  ITrainingFormLocationDateService,
} from '@/interfaces/form-interfaces/TrainingDataFormInterface'
import ITrainingDataResponse from '@/interfaces/training/TrainingDataInterface'
import { IPaginatedResponse, IResponse } from '@/interfaces/ResponseInterface'
import User from '@/classes/user/user'
import ITrainingProfileListResponse from '@/interfaces/training/TrainingProfileListInterface'

export default class TrainingService {
  static list(query: any = {}, perPage: number = 15): Promise<IPaginatedResponse<TrainingData[]>> {
    return http
      .get('trainings', {
        params: {
          ...query,
          per_page: perPage,
        },
      })
      .then((response: AxiosResponse) => ({
        data: response.data.data.map((item: ITrainingDataResponse) => new TrainingData(item)),
        meta: response.data.meta,
      }))
  }

  static profileTrainingList(
    query: any = {},
    perPage: number = 15
  ): Promise<IPaginatedResponse<ITrainingProfileListResponse[]>> {
    return http
      .get('trainings/profile-training-list', {
        params: {
          ...query,
          per_page: perPage,
        },
      })
      .then((response: AxiosResponse) => ({
        data: response.data.data,
        meta: response.data.meta,
      }))
  }

  static getClassifiers(list: string = ''): Promise<Classifier[]> {
    return http.get(`classifiers/${list}`).then((response) => {
      return response.data.data.map((classifier: IClassifierResponse) => new Classifier(classifier))
    })
  }

  static getClassifiersByCategory(category: string): Promise<Classifier[]> {
    return http.get(`classifiers/by-category-name/${category}`).then((response) => {
      return response.data.data.map((classifier: IClassifierResponse) => new Classifier(classifier))
    })
  }

  static getEditTrainingData(trainingUuid: string): Promise<ITrainingLocationDateResponse> {
    return http.get(`training-location-dates/${trainingUuid}/edit`).then((response) => {
      return response.data.data
    })
  }

  static updateTraining(data: TrainingLocationDate): Promise<AxiosResponse> {
    const payload = this.getUpdateTrainingPayload(data)

    return http.put(`training-location-dates/${data.uuid}/update-with-training`, payload)
  }

  static deleteTraining(uuid: string): Promise<AxiosResponse> {
    return http.delete(`training-location-dates/${uuid}/full`)
  }

  static getClassifiersByCategoryId(categoryId: number): Promise<IClassifiersById[]> {
    return http.get(`classifiers/by-category/${categoryId}`).then(async (response) => response.data.data)
  }

  static getClassifiersByParent(parent: string | number | null): Promise<Classifier[]> {
    return http.get(`classifiers/by-parent/${parent}`).then((response) => {
      return response.data.data.map((classifier: IClassifierResponse) => new Classifier(classifier))
    })
  }

  static getClassifier(id: number | string | null): Promise<Classifier[]> {
    return http.get(`classifiers/${id}`).then((response) => {
      return response.data.data.map((classifier: IClassifierResponse) => new Classifier(classifier))
    })
  }

  static createNewTraining(
    data: ITrainingDataForm,
    active_step: number,
    completed_step: number
  ): Promise<ITrainingDataForm> {
    return http.post('trainings', data).then(async (response) => {
      return new TrainingData({
        ...response.data.data,
        active_step,
        completed_step,
      }).toFormData()
    })
  }

  static submitTrainingInfo(
    data: ITrainingDataForm,
    active_step: number,
    completed_step: number
  ): Promise<ITrainingDataForm> {
    return http.put(`trainings/${data.uuid}`, data).then((response) => {
      return new TrainingData({ ...response.data.data, active_step, completed_step }).toFormData()
    })
  }

  static submitTrainingLocations(data: ITrainingFormLocation[]): Promise<ITrainingFormLocation[]> {
    return http
      .put('training-locations/create-or-update-many', { trainingLocations: data })
      .then((response) =>
        response.data.data.map((location: ITrainingLocationResponse) => new TrainingLocation(location).toFormData())
      )
  }

  static submitTrainingLocationDates(data: ITrainingFormLocationDate[]): Promise<ITrainingFormLocationDate[]> {
    return http
      .put('training-location-dates/create-or-update-many', { trainingLocationDates: data })
      .then((response) =>
        response.data.data.map((date: ITrainingLocationDateResponse) => new TrainingLocationDate(date).toFormData())
      )
  }

  static submitTrainingLocationDateServices(
    data: ITrainingFormLocationDateService[]
  ): Promise<ITrainingFormLocationDateService[]> {
    return http
      .put('training-location-date-services/create-or-update-many', { trainingLocationDateServices: data })
      .then((response) =>
        response.data.data.map((service: ITrainingLocationDateServiceResponse) =>
          new TrainingLocationDateService(service).toFormData()
        )
      )
  }

  static generateTrainingServices(uuid: string | undefined): Promise<ITrainingFormLocationDateService[]> {
    return http
      .get(`training-location-date-services/generate-by-training/${uuid}`)
      .then((response) =>
        response.data.data.map((service: ITrainingLocationDateServiceResponse) =>
          new TrainingLocationDateService(service).toFormData()
        )
      )
  }

  static getTrainingList(page: number, search: string, filters?: Record<string, Filter>): Promise<AxiosResponse> {
    return http
      .get(`training-location-dates?per_page=10&page=${page}&q=${search}${this.formatFilters(filters)}`)
      .then((response) => response.data)
  }

  static getTrainingActiveList(page: number, search: string, filters?: Record<string, Filter>): Promise<AxiosResponse> {
    return http
      .get(`training-location-dates/active?per_page=10&page=${page}&q=${search}${this.formatFilters(filters)}`)
      .then((response) => response.data)
  }

  static getTrainingListByCompany(user: User): Promise<AxiosResponse[]> {
    return http.get(`training-location-dates?filter-user.id=${user.id}`).then((response) => response.data)
  }

  static getTraining(uuid: string): Promise<TrainingLocationDate> {
    return http
      .get(`training-location-dates/get-by-slug/${uuid}`)
      .then((response) => new TrainingLocationDate(response.data.data))
  }

  static formatFilters(filters?: Record<string, Filter>): string {
    if (!filters) return ''

    let filterString: string = ''
    _.each(filters, (filter: Filter) => {
      filterString += filter.createFilterString()
    })

    return filterString
  }

  static getCountryFilterList(): Promise<string[]> {
    return http.get('training-location-dates/get-search-list').then((response) => response.data.data.countries)
  }

  static removeLocationLineFromDB(index: number, array: TrainingLocation[]): Promise<AxiosResponse> {
    return http.delete(`training-locations/${array[index].uuid}`)
  }

  static removeLocationDateFromDB(uuid: string | undefined): Promise<AxiosResponse> {
    return http.delete(`training-location-dates/${uuid}`)
  }

  private static getUpdateTrainingPayload(data: TrainingLocationDate) {
    const trainingLocationDateServices: Array<{ isActive: boolean; id: number }> = []
    data.trainingLocationDateServices?.forEach((item) => {
      trainingLocationDateServices.push({
        isActive: item.isActive,
        id: item.id,
      })
    })

    return {
      from: data.from,
      to: data.to,
      seats: data.seats,
      vat: data.vat,
      price: data.price,
      title: data.title,
      trainingLocation: {
        location: data.trainingLocation.location,
        country: data.trainingLocation.country,
        city: data.trainingLocation.city,
        address: data.trainingLocation.address,
        post: data.trainingLocation.post,
      },
      trainingLocationDateServices,
    }
  }

  static async getTopTrainings(): Promise<TrainingData[]> {
    return http
      .get('training-location-dates/get-top')
      .then((response: AxiosResponse<IResponse<ITrainingDataResponse[]>>) =>
        response.data.data.map((trainingData: ITrainingDataResponse) => new TrainingData(trainingData))
      )
  }
}
